import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import 'swiper/swiper.scss';
import { Link, graphql } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import * as BannerStyle from '../../components/banner/banner.module.scss'
import { GatsbyImage } from "gatsby-plugin-image"


const IndexST = ({ data }) => (
  <Layout>
    <Seo title="Welcome" bodyClass='st' />
    
  <div className="column column--100">


  <Swiper
      spaceBetween={24}
      slidesPerView={1}
      loop={true}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      className={BannerStyle.banner}
    >
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_foresight.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><a aria-label="Double Take Apple Music" href="https://music.apple.com/ca/album/double-take-single/1615056784" target="_blank" rel="noreferrer"><GatsbyImage className={BannerStyle.banner__image} image={data.banner_doubletake.childImageSharp.gatsbyImageData}/></a></SwiperSlide>
      <SwiperSlide><a aria-label="Low Key Apple Music" href="https://music.apple.com/ca/album/lowkey-single/1608919223" target="_blank" rel="noreferrer"><GatsbyImage className={BannerStyle.banner__image} image={data.banner_lowkey.childImageSharp.gatsbyImageData}/></a></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_detour.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_asr.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/bio'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_bio.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_girlfriday.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_tionne.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_cake.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_dose2.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/shop'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_sideeffects_video.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
      <SwiperSlide><Link to='/st/bio'><GatsbyImage className={BannerStyle.banner__image} image={data.banner_specyalmusiclive.childImageSharp.gatsbyImageData}/></Link></SwiperSlide>
     
     
    </Swiper>
    <center><span className={style.titleLowercase}>The artist known as <em>Specyal&nbsp;T</em> is an exceptionally talented, multi-instrumentalist, vocalist, songwriter, composer, producer, actor, writer &amp; poet; <em>who's on a mission to expand the urban market with her unique brand of sound.</em></span></center>

  </div>
  </Layout>
)

export default IndexST

export const query = graphql`
  query {
    banner_foresight: file(relativePath: {eq: "banners/banner-foresight-available.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_doubletake: file(relativePath: {eq: "banners/banner-doubletake.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_lowkey: file(relativePath: {eq: "banners/banner-lowkey.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_detour: file(relativePath: {eq: "banners/banner-slight-detour.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_asr: file(relativePath: {eq: "banners/banner-asr.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_girlfriday: file(relativePath: {eq: "banners/banner-girlfriday.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_tionne: file(relativePath: {eq: "banners/banner-tionne.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_cake: file(relativePath: {eq: "banners/banner-cake.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_dose2: file(relativePath: {eq: "banners/banner-dose2.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_sideeffects_video: file(relativePath: {eq: "banners/banner-sideeffects_video.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_bio: file(relativePath: {eq: "banners/banner-bio.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    banner_specyalmusiclive: file(relativePath: {eq: "banners/banner-specyalmusic-live.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
